import React, { useState, useEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const SearchResultsFloorplanPopup = (props) => {

    // Property details images lightbox
    var propertyImagePopup = []
    
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    } 
    
    propertyImagePopup.push(props?.propertyFloorPlan && props?.propertyFloorPlan?.url)
	// Property details images lightbox

    return (
        <React.Fragment>
            <div className="floor-plan">
                <button onClick={(e) => openLighboxMobile(e,0)}>
                    <i className="icon-floor-plan"></i>
                </button>
            </div>

            {isOpen && propertyImagePopup.length != 0 && (
                <Lightbox
                    wrapperClassName="light-box-image-zoom"
                    mainSrc={propertyImagePopup[photoIndex]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + propertyImagePopup.length - 1) % propertyImagePopup.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyImagePopup.length)
                    }
                />
            )}
        </React.Fragment>
    )
}

export default SearchResultsFloorplanPopup